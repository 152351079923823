import React, { useState } from 'react'

import '../../css/default.css'
import logo from '../../images/Logo-AG.svg'

const Home = () => {
  const [email, setEmail] = useState('')
  const [registeredMessage, setRegisteredMessage] = useState('')

  async function RegisterToNewsletter() {
    if (/\S+@\S+\.\S+/.test(email)) {
      try {
        const res = await fetch('https://api.aid-guardian.com/add.php', {
          method: 'POST',
          body: JSON.stringify({
            email: email,
          }),
        })
        // const resJson = await res.json();
        if (res.status === 200) {
          setRegisteredMessage('Vielen Dank, Sie haben sich erfolgreich registriert!')
          console.log('User created successfully')
        } else {
          console.log('Some error occured')
          setRegisteredMessage('es ist ein Fehler aufgetreten')
        }
      } catch (err) {
        console.log(err)
      }
    } else {
      setRegisteredMessage('bitte geben Sie eine gültige E-Mail Adresse an')
    }

    return
  }

  return (
    <div id='comingsoon'>
      <div id='comingsoon-picture'></div>
      <div id='comingsoon-content'>
        <div id='comingsoon-innercontent'>
          <div id='container-logo'>
            <img src={logo} />
          </div>
          <div id='container-text'>
            <h2>
              Wir kommen mit <div className='text-highlighted'>Sicherheit</div>!
            </h2>
            <p>
              Bitte tragen Sie hier Ihre E-Mail ein, <br />
              um nichts zu verpassen und <br />
              zum Release der Website live dabei sein zu können!
            </p>
            <div id='form-newsletter'>
              <input
                type='email'
                placeholder='Ihre E-Mail'
                name='mail'
                id='newsletter-mail'
                className='webflow-style-input'
                onChange={(e) => setEmail(e.target.value)}
              ></input>
              <button id='newsletter-submit' onClick={RegisterToNewsletter}>
                Registrieren
              </button>
            </div>
            <div id='registered-message'>
              <p>{registeredMessage}</p>
            </div>
          </div>
          <div id='container-socials'>
            <div id='text-social'>
              ... Follow <div className='text-highlighted'>us</div>!
            </div>
            <div id='icons-social'>
              <div className='icon-facebook'>
                <a href='https://www.facebook.com/aidguardian' target='_blank' rel='noreferrer'></a>
              </div>
              <div className='icon-instagram'>
                <a
                  href='https://www.instagram.com/aidguardian/'
                  target='_blank'
                  rel='noreferrer'
                ></a>
              </div>
              <div className='icon-linkedin'>
                <a
                  href='https://www.linkedin.com/company/aidguardian/'
                  target='_blank'
                  rel='noreferrer'
                ></a>
              </div>
              <div className='icon-twitter'>
                <a href='https://twitter.com/AidGuardian' target='_blank' rel='noreferrer'></a>
              </div>
            </div>
          </div>
          <div id='imprint-box'>
            <a href='/impressum'>Impressum</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
